import {Search, SearchArea, SearchAreaEnums, SearchRequest} from './fuse-search';

document.addEventListener('DOMContentLoaded', () => {
    const searchParentElement = document.querySelector('.fullpage-search');
    if (!searchParentElement) {
        return;
    }
    Search.loadJS(() => {
        const search = new Search(searchParentElement, (request) => {
            updateTitleAndParameters(request);
        });

        const updateTitleAndParameters = (request: SearchRequest) => {
            const queryTitle = (request.query.length > 0 ? ` - "${request.query}"` : '');
            const sectionTitle = (request.area !== SearchArea.All ? ` - ${request.areaTitle}` : '');
            const title = search.lang_title + queryTitle + sectionTitle;
            let urlParams = '';
            const params: Array<string> = [];
            if (request.area !== SearchArea.All) {
                params.push(`area=${request.areaId}`);
            }
            if (request.query.length > 0) {
                params.push(`query=${encodeURIComponent(request.query)}`);
            }
            if (params.length > 0) {
                urlParams = `?${params[0]}`;
                if (params.length > 1) {
                    urlParams += `&${params.slice(1).join('&')}`;
                }
            }
            history.pushState(null, title, urlParams.length === 0 ? window.location.pathname : urlParams);
            document.title = title;
        };

        const searchByParameter = (url: string): boolean => {
            const searchParams = new URLSearchParams(url);
            if (searchParams.has('area')) {
                const enumID = searchParams.get('area') || 'all';
                const id = SearchAreaEnums[enumID];
                if (id) {
                    search.setCurrentSearch(id);
                    const tabLinkElement = searchParentElement.querySelector(`.search-sections-tabs button[data-section=${enumID}]`);
                    if (tabLinkElement) {
                        const tab = new window.bootstrap.Tab(tabLinkElement)
                        tab?.show();
                    }
                }
            }
            if (searchParams.has('query')) {
                search.inputElement.value = searchParams.get('query') || '';
                search.search();
                return true;
            }
            return false;
        };

        const orgpopstate = window.onpopstate;
        window.onpopstate = function (e) {
            orgpopstate?.call(window, e);
            searchByParameter(window.location.search);
        };
        if (!searchByParameter(window.location.search)) {
            search.preloadIndex();
        }
    });
})
